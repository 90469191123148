import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';

import { deleteInbox } from '@/services/api/inbox';

export const useClearInbox = () =>
  useCallback(async ({ name, trigger }: { trigger: 'url' | 'button'; name: string }) => {
    try {
      const response = await deleteInbox(name, trigger);
      if (!response.ok) {
        Sentry.captureMessage(`${response.statusText} (${response.status})`);
      }
      return response;
    } catch {
      Sentry.captureMessage('Failed to clear inbox', 'info');
      return undefined;
    }
  }, []);
