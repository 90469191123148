import config from '@/constants/config';

export const getMessage = (inboxName: string, messageId: string = '') =>
  fetch(`${config.API_HOST}/inbox/${inboxName}/messages/${messageId}`);

export const getMessages = (inboxName: string) =>
  fetch(`${config.API_HOST}/inbox/${inboxName}/messages/`);

export const deleteInbox = (inboxName: string, trigger: 'url' | 'button') =>
  fetch(`${config.API_HOST}/inbox/${inboxName}/?trigger=${trigger}`, { method: 'DELETE' });
