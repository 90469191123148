import * as Sentry from '@sentry/browser';
import { useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import type { ShortInboxMessage } from '@/types/messages';

import CopyButton from '@/components/CopyButton';
import { useClearInbox } from '@/hooks/useClearInbox';

import Button from '../Button';

import styles from './Layout.module.scss';

import Logo from '@/images/logo.svg?react';

const Layout = () => {
  const navigate = useNavigate();
  const { name = '' } = useParams();
  const email = `${name}@${process.env.REACT_APP_INBOX_DOMAIN}`.toLowerCase();
  const clearInbox = useClearInbox();
  const [messages, setMessages] = useState<ShortInboxMessage[]>([]);

  const outletContext = useMemo(() => ({ messages, setMessages }), [messages, setMessages]);

  const handleClearInbox = async () => {
    try {
      await clearInbox({ trigger: 'button', name });
      setMessages([]);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className={[styles.container].join(' ')}>
      <header className={styles.header}>
        <Logo className={styles.logo} onClick={() => navigate('/')} />
        <div className={styles.actions}>
          <div id="copy-mail" className={styles.inboxName}>
            <CopyButton value={email} small />
            <span>{email}</span>
          </div>
          <Button id="clear-inbox" onClick={handleClearInbox} variant="default">
            Clear inbox
          </Button>
        </div>
      </header>
      <main className={styles.content}>
        <Outlet context={outletContext} />
      </main>
    </div>
  );
};

export default Layout;
